<template>
  <div>
    <understanding />
    <section
      class="mbr-section content4 cid-rIBoONlqq0"
      id="puntos-tener-cuenta"
    >
      <div class="container">
        <div class="media-container-row">
          <div class="title col-12 col-md-8">
            <h2 class="align-center pb-3 mbr-fonts-style display-2">
              <strong>Puntos </strong>a tener en cuenta para cuando se toma el
              <strong>Dioxi7</strong>
            </h2>
            <h3
              class="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5"
            >
              Antes de tomar el Dioxi7 se recomienda realizar estos<strong
                >3 importantes puntos:&nbsp;</strong
              >
            </h3>
          </div>
        </div>
      </div>
    </section>
    <section
      class="progress-bars4 circle-progress-section cid-rIBoEiOD7J"
      id="progress-bars1-m"
    >
      <svg class="svg-gradient">
        <linearGradient>
          <stop offset="0%" stop-color="#1695c1"></stop>
          <stop offset="100%" stop-color="#7aba59"></stop>
        </linearGradient>
      </svg>
      <div class="container">
        <div class="media-container-row">
          <div class="card p-3 align-left">
            <div class="wrap">
              <div
                class="pie_progress progress1"
                role="progressbar"
                data-goal="50"
              >
                <h5
                  class="number mbr-bold align-center mbr-fonts-style display-2"
                >
                  1
                </h5>
              </div>
            </div>
            <div class="content">
              <div class="mbr-crt-title">
                <h4
                  class="card-title mbr-semibold pb-2 mbr-fonts-style display-7"
                >
                  SELLAR INTESTINOS
                </h4>
              </div>
              <div class="mbr-crt-text">
                <p class="mbr-fonts-style mbr-regular card-text display-4">
                  Lo ideal es sellar los intestinos con colágeno biodisponible y
                  desinflamar.<br />
                </p>
              </div>
            </div>
          </div>
          <div class="card p-3 align-left">
            <div class="wrap">
              <div
                class="pie_progress progress2"
                role="progressbar"
                data-goal="75"
              >
                <h5
                  class="number mbr-bold align-center mbr-fonts-style display-2"
                >
                  2
                </h5>
              </div>
            </div>
            <div class="content">
              <div class="mbr-crt-title">
                <h4
                  class="card-title mbr-semibold pb-2 mbr-fonts-style display-7"
                >
                  DESPARASITACIÓN
                </h4>
              </div>
              <div class="mbr-crt-text">
                <p class="mbr-fonts-style mbr-regular card-text display-4">
                  Es muy recomendado hacer una desparasitación antes de tomar
                  Dioxi7, MMS, OE.<br />
                </p>
              </div>
            </div>
          </div>
          <div class="card p-3 align-left">
            <div class="wrap">
              <div
                class="pie_progress progress3"
                role="progressbar"
                data-goal="100"
              >
                <h5
                  class="number mbr-bold align-center mbr-fonts-style display-2"
                >
                  3
                </h5>
              </div>
            </div>
            <div class="content">
              <div class="mbr-crt-title">
                <h4
                  class="card-title mbr-semibold pb-2 mbr-fonts-style display-7"
                >
                  ANTIOXIDANTES
                </h4>
              </div>
              <div class="mbr-crt-text">
                <p class="mbr-fonts-style mbr-regular card-text display-4">
                  Todos los fines de semana se toman antioxidantes y los
                  probióticos son a diario.<br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cid-rIBrbiObTX" id="content4-p">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6 col-lg-4 md-pb">
            <div class="align-wrap">
              <div class="icons-wrap">
                <div class="icon-wrap">
                  <span class="mbr-iconfont mbrib-target"></span>
                  <div class="text-wrap">
                    <h3 class="icon-title mbr-bold mbr-fonts-style display-5">
                      Caldo de Huesos Desiflamación
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <ul class="list">
              <li class="item-wrap">
                <span
                  class="mbr-iconfont mbri-drag-n-drop"
                  style="color: rgb(118, 118, 118); fill: rgb(118, 118, 118)"
                ></span>
                <h4 class="item1 mbr-regular mbr-fonts-style display-7">
                  Sellamiento Intestinal
                </h4>
              </li>
              <li class="item-wrap">
                <span
                  class="mbr-iconfont mbrib-success"
                  style="color: rgb(118, 118, 118); fill: rgb(118, 118, 118)"
                ></span>
                <h4 class="item1 mbr-regular mbr-fonts-style display-7">
                  Desinflamar con Cúrcuma
                </h4>
              </li>
              <li class="item-wrap">
                <span
                  class="mbr-iconfont mbrib-sun"
                  style="color: rgb(118, 118, 118); fill: rgb(118, 118, 118)"
                ></span>
                <h4 class="item1 mbr-regular mbr-fonts-style display-7">
                  <strong
                    ><a href="recomendaciones.html#extHeader20-ka"
                      >&gt; Recomendaciones 1</a
                    ></strong
                  >
                </h4>
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-lg-4 md-pb">
            <div class="align-wrap">
              <div class="icons-wrap">
                <div class="icon-wrap">
                  <span class="mbr-iconfont mbrib-speed"></span>
                  <div class="text-wrap">
                    <h3 class="icon-title mbr-bold mbr-fonts-style display-5">
                      Antioxidantes y Probióticos
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <ul class="list">
              <li class="item-wrap">
                <span
                  class="mbr-iconfont mbri-drag-n-drop"
                  style="color: rgb(118, 118, 118); fill: rgb(118, 118, 118)"
                ></span>
                <h4 class="item1 mbr-regular mbr-fonts-style display-7">
                  Tomas de Antioxidantes
                </h4>
              </li>
              <li class="item-wrap">
                <span
                  class="mbr-iconfont mbrib-success"
                  style="color: rgb(118, 118, 118); fill: rgb(118, 118, 118)"
                ></span>
                <h4 class="item1 mbr-regular mbr-fonts-style display-7">
                  Tomas de Probióticos
                </h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="accordion1 cid-rIAzBK1PQz" id="usos-multifaceticos-dioxi7">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="section-head align-left">
              <h3
                class="mbr-section-subtitle mbr-semibold pb-2 mbr-fonts-style display-7"
              >
                + DIÓXIDO DE CLORO / MMS / Dioxi7 / OE / Dioxi7
              </h3>
              <h2
                class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-2"
              >
                <strong
                  >Los&nbsp;<a href="#" class="text-primary"
                    >Usos&nbsp;</a
                  ></strong
                >del
                <span style="font-weight: normal">Multifacético</span> Dioxi7
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="mbr-fonts-style pb-4 align-left text display-7"></p>
            <div style="text-align: justify">
              <span style="font-size: 1.2rem"
                >(Dioxi7) es una sustancia que posee diversos usos: es un
                desinfectante de alto espectro y de cero toxicidad, el cual se
                puede usar para potabilizar el agua que tomamos, para
                desinfectar nuestros alimentos, para desinfectar todos los
                elementos de uso diario en casas, oficinas, para desinfección en
                laboratorios y ascepcia hospitalaria, en cocina Kosher, para
                limpieza de la sangre en transfuciones de sanguíneas, para
                deodorización, entre otros. &nbsp;</span
              >
            </div>
            <div style="text-align: justify"><br /></div>
            <div style="text-align: justify">
              <span style="font-size: 1.2rem"
                >El Dioxi7 también es ampliamente usado como elemento
                terapéutico dado que elimina virus, bacterias, hongos y toxinas
                con total eficacia, incluso se usa el Dióxido de Cloro como un
                efectivo desinfectante y desintoxicante aéreo &nbsp;(funciona
                incluso para evitar contagio en forma gaseosa).</span
              >
            </div>
            <p></p>
          </div>
          <div class="col-12 col-lg-6 accordion-section">
            <div class="accordion-content">
              <div
                id="bootstrap-accordion_9"
                class="panel-group accordionStyles accordion"
                role="tablist"
                aria-multiselectable="true"
              >
                <div class="card">
                  <div class="card-header" role="tab" id="headingOne">
                    <a
                      role="button"
                      class="panel-title collapsed text-black"
                      data-toggle="collapse"
                      data-core=""
                      href="#collapse1_9"
                      aria-expanded="false"
                      aria-controls="collapse1"
                    >
                      <h4 class="mbr-fonts-style display-7">
                        Usos Terapéuticos
                      </h4>
                      <span
                        class="sign mbr-iconfont mbri-arrow-down inactive"
                      ></span>
                    </a>
                  </div>
                  <div
                    id="collapse1_9"
                    class="panel-collapse noScroll collapse"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    data-parent="#bootstrap-accordion_9"
                  >
                    <div class="panel-body p-4">
                      <p class="mbr-fonts-style panel-text display-7">
                        El poder medicinal del Dioxi7 lo descubrió por
                        casualidad Jim Humble hace ya más de 40 años, luego
                        Andreas Kalcker profundizó en sus usos terapéuticos.<br />
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" role="tab" id="headingTwo">
                    <a
                      role="button"
                      class="collapsed panel-title text-black"
                      data-toggle="collapse"
                      data-core=""
                      href="#collapse2_9"
                      aria-expanded="false"
                      aria-controls="collapse2"
                    >
                      <h4 class="mbr-fonts-style display-7">
                        Usos como Desinfectante
                      </h4>
                      <span
                        class="sign mbr-iconfont mbri-arrow-down inactive"
                      ></span>
                    </a>
                  </div>
                  <div
                    id="collapse2_9"
                    class="panel-collapse noScroll collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                    data-parent="#bootstrap-accordion_9"
                  >
                    <div class="panel-body p-4">
                      <p class="mbr-fonts-style panel-text display-7">
                        El Dioxi7 se usa como desinfectante de alto rango por
                        que se puede usar desde para potabilizar agua,
                        desinfectar alimentos, material quirurgico, animales,
                        hasta para limpiar la sangre de donantes (patente
                        alcide).
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" role="tab" id="headingThree">
                    <a
                      role="button"
                      class="collapsed text-black panel-title"
                      data-toggle="collapse"
                      data-core=""
                      href="#collapse3_9"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      <h4 class="mbr-fonts-style display-7">
                        Usos como Deodorizador
                      </h4>
                      ¡
                      <span
                        class="sign mbr-iconfont mbri-arrow-down inactive"
                      ></span>
                    </a>
                  </div>
                  <div
                    id="collapse3_9"
                    class="panel-collapse noScroll collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    data-parent="#bootstrap-accordion_9"
                  >
                    <div class="panel-body p-4">
                      <p class="mbr-fonts-style panel-text display-7">
                        El Dioxi7 es un gas altamente neutralizador de patógenos
                        y toxinas, cuando liberamos este gas en un ambiente
                        cerrado de realizará una desinfección aérea muy eficáz,
                        esta técnica se usa para evitar contagios entre personas
                        que están en un lugar cerrado, también para deodorizar
                        frigorificos, restaurants, carnicerias, peluquerías,
                        etc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="extHeader cid-rS6ZJEoPHC mbr-fullscreen mbr-parallax-background"
      id="importancia-probioticos"
    >
      <div class="mbr-overlay" style="opacity: 0.8"></div>
      <div class="container-fluid header-content">
        <div class="row text-row">
          <div class="col-md-12 col-lg-7">
            <div class="title-cont align-left">
              <h1
                class="mbr-section-title align-left mbr-white pb-3 mbr-fonts-style mbr-bold display-2"
              >
                <br /><span style="font-weight: normal">Son de verdad</span> tan
                importantes
                <span style="font-weight: normal">los probióticos?</span>
              </h1>
            </div>
            <div class="mbr-white pb-3 align-left">
              <p
                class="mbr-section-subtitle mbr-fonts-style mbr-text display-7"
              ></p>
              <div style="text-align: justify">
                <span style="font-size: 1.2rem"
                  >Los probióticos se encuentran en bebidas y alimentos
                  fermentados los cuales traen consigo billones de saludables
                  microorganismos, los mismos que te ayudarán a tener una
                  correcta digestión, a fortalecer tu sistema inmune, y como si
                  eso fuera poco estos probióticos ayudan a fabricar serotonina,
                  dopamina, y otros neurotransmisores, los cuales son sustancias
                  que dan bienestar y le hacen muy bien a tu cerebro y a todo tu
                  cuerpo.</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12">
            <div class="mbr-figure">
              <img
                src="@/assets/images/probioticos-imagen-490x503.png"
                alt=""
                title=""
              />
            </div>
          </div>
        </div>
        <div class="row features-row">
          <div class="card p-5 col-12 col-md-6 col-lg-3">
            <div class="card-img pb-4">
              <span class="mbr-iconfont mbri-question"></span>
            </div>
            <div class="card-box">
              <h4 class="card-title mbr-fonts-style display-5">
                <strong>Qué son los probióticos?</strong>
              </h4>
              <p class="mbr-text mbr-fonts-style display-4">
                Los probióticos son aquellos alimentos o suplementos que
                contienen microorganismos vivos, como bacterias benéficas, que
                se pueden utilizar para modificar o mejorar el equilibrio
                bacteriano intestinal y mejoran la salud en general del
                organismo.<br /><br />Estos microorganismos te dan beneficios
                como facilitar la digestión y la absorción de nutrientes,
                fortalecer el sistema inmunológico y mejorar los niveles de
                energía y de satisfacción en el ser humano.<br /><br />Los
                probióticos intestinales son microorganismos vivos (bacterias,
                levaduras...) que, ingeridos en cantidades adecuadas para que
                lleguen vivos al intestino, producen un efecto benéfico en la
                salud al ayudar a reequilibrar la microbiota intestinal.<br />
              </p>
            </div>
          </div>

          <div class="card p-5 col-12 col-md-6 col-lg-3">
            <div class="card-img pb-4">
              <span class="mbr-iconfont icon54-v1-bug-protect"></span>
            </div>
            <div class="card-box">
              <h4 class="card-title mbr-fonts-style display-5">
                <strong>Qué es la microbiota intestinal?</strong>
              </h4>
              <p class="mbr-text mbr-fonts-style display-4">
                Se denomina microbiota intestinal a la comunidad de
                microorganismos vivos que alberga el tubo digestivo humano y que
                se ha adaptado a vivir en la superficie del intestino desde hace
                milenios. <br /><br />El intestino del individuo nace estéril y
                adquiere su colonización microbiana inmediatamente tras el
                nacimiento, de la madre y del entorno inicial. <br /><br />La
                gran mayoría de los organismos de la microbiota permanecen
                siempre en el intestino, mientras que otra parte llamada:
                “microorganismos en tránsito” habitan temporalmente, ya que se
                ingieren a través de los alimentos y bebidas fundamentalmente.
              </p>
            </div>
          </div>

          <div class="card p-5 col-12 col-md-6 col-lg-3">
            <div class="card-img pb-4">
              <span class="mbr-iconfont mdi-action-bug-report"></span>
            </div>
            <div class="card-box">
              <h4 class="card-title mbr-fonts-style display-5">
                <strong>La Flora Intestinal y la Estomacal.</strong>
              </h4>
              <p class="mbr-text mbr-fonts-style display-4">
                El estómago y el duodeno son las partes del intestino con menos
                microorganismos, sobretodo porque la secreción ácida del
                estómago y la bilis destruyen una gran parte de las bacterias.
                <br /><br />El número de bacterias va aumentando a lo largo del
                intestino y es en el colon donde existe la mayor densidad de
                población de bacterias y microbios benéficos para nuestro
                organismo.<br /><br />Este conjunto de microbios presenta una
                estrecha interdependencia entre ellos y conforman la conocida
                microbiota intestinal o también llamada composta intestinal.<br /><br />El
                intestino humano contiene unos 100 billones de bacterias de unas
                500 a 1000 especies distintas.<br />
              </p>
            </div>
          </div>

          <div class="card p-5 col-12 col-md-6 col-lg-3">
            <div class="card-img pb-4">
              <span class="mbr-iconfont mdi-hardware-security"></span>
            </div>
            <div class="card-box">
              <h4 class="card-title mbr-fonts-style display-5">
                <strong>La Microbiota Intestinal y la inmunidad.</strong>
              </h4>
              <p class="mbr-text mbr-fonts-style display-4">
                La importancia de la microbiota intestinal en la salud del
                cuerpo no tiene precedentes. La microbiota del aparato digestivo
                es un ecosistema extremadamente variado y complejo, la cual está
                constituida principalmente por: Firmicutes, Bacteroidetes,
                Actinobacteria, Proteobacteria, entre otras bacterias menos
                frecuentes, que participan en la defensa del organismo frente a
                los patógenos, colaboran en la digestión de los alimentos,
                metabolizan algunas sustancias que el cuerpo humano es incapaz
                de hacerlo, produce y/o consume sustancias que pueden tener
                efectos o ser necesarias para el organismo, estabiliza
                estructuralmente la barrera intestinal, y tiene un papel muy
                importante en la maduración y modulación del sistema
                inmunológico.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="features1 cid-rSusqWIEfs" id="info1-s9">
      <div class="container">
        <div class="row">
          <div class="card col-12 col-md-12 col-lg-4">
            <div class="card-wrapper">
              <div class="card-box">
                <h4
                  class="card-title mbr-black align-left mbr-bold mbr-fonts-style display-2"
                >
                  Cuidame y sáname de los
                </h4>
                <h5
                  class="mbr-text align-left mbr-bold mbr-fonts-style display-2"
                >
                  &nbsp; patógenos!
                </h5>
              </div>
            </div>
          </div>
          <div class="card col-12 col-md-12 col-lg-4">
            <div class="card-img align-center">
              <img src="@/assets/images/perrito.png" />
            </div>
          </div>
          <div class="card col-12 col-md-12 col-lg-4">
            <div class="bone-col m-auto">
              <div class="link-wrap">
                <img class="bone" src="@/assets/images/bone.png" />
                <h3
                  class="mbr-section-title mbr-white align-left mbr-bold mbr-fonts-style display-7"
                >
                  <a href="#dioxi7mascotas" class="text-success"
                    ><strong>DESPARASITACIÓN</strong></a
                  >
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mbr-section content4 cid-rIAvSl6P0F" id="dr-hulda-clark">
      <div class="container">
        <div class="media-container-row">
          <div class="title col-12 col-md-8">
            <h2 class="align-center pb-3 mbr-fonts-style display-2">
              <strong>La Dra. Hulda Clark&nbsp;</strong>exponiendo sobre la
              Parasitósis y <strong>la Enfermedad</strong>
            </h2>
            <h3
              class="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5"
            >
              Los Parásitos como tal ya son nocivos, pero en realidad los más
              nocivo de ellos son sus desechos metabólicos (en castellano sus
              heces y restos) ya que estos restos estan cargados de diversos
              patógenos, metales pesados y toxinas todo un coctel tóxico y muy
              nocivo para nosotros ya que estas sutancias nos terminan
              enfermando, es por eso que es tan importante hacer una correcta y
              profunda desparasitación y desintoxicación.
            </h3>
          </div>
        </div>
      </div>
    </section>
    <section class="header1 cid-rIBcDXRpQM" id="header10-j">
      <div class="round round1 rev"></div>
      <div class="round round2 rev"></div>
      <div class="round round3"></div>
      <div class="round round4 rev"></div>
      <div class="round round5"></div>
      <div class="round round6 rev"></div>
      <div class="round round7"></div>
      <div class="round round8 rev"></div>
      <div class="round round9 rev"></div>
      <div class="round round10"></div>
      <div class="round round11"></div>
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-10 align-center">
            <h1
              class="mbr-section-title mbr-regular pb-2 mbr-fonts-style display-2"
            >
              Extraído de su libro <br /><strong
                >"La Cura de Todas las Enfermedades"</strong
              >
            </h1>
            <p class="mbr-text pb-2 mbr-light mbr-fonts-style display-7">
              "He comprobado que <strong>el eczema</strong> está causado por
              lombrices; <strong>las crisis epilépticas</strong> ocurren cuando
              una lombriz denominada Ascaris consigue penetrar en el cerebro;
              <strong>la esquizofrenia y la depresión</strong> están causadas
              por parásitos alojados en el cerebro; <strong>el asma</strong> lo
              causa el Ascaris en los pulmones;
              <strong>la diabetes</strong> está causada por el parásito
              Eurytrema; la causa de <strong>las migrañas</strong> son el
              Strongyloides, <strong>la obesidad y ansiedad</strong> son casi
              siempre la Cándida Albicans, y así muchisimas otras enfermedades
              más". <br />
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="header4 cid-rIBcEEKRjI" id="content05-k">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-10 align-left">
            <h3
              class="mbr-section-subtitle align-left mbr-white mbr-regular pb-2 mbr-fonts-style display-4"
            >
              AQUÍ SEGUIMOS CON PARTE DE&nbsp;<strong>SU LIBRO</strong>
            </h3>
            <h1
              class="mbr-section-title align-left mbr-white pb-2 mbr-fonts-style display-2"
            >
              Los<strong> parásitos</strong> son la causa de casi&nbsp;<strong
                >todas las enfermedades </strong
              >y de muchos y<strong> molestos síntomas</strong>
            </h1>
            <p class="mbr-text align-left mbr-fonts-style display-7"></p>
            <div style="text-align: justify">
              <span style="color: rgb(0, 0, 0); font-size: 1.2rem"
                >Los parásitos pasan por varias etapas de crecimiento. A partir
                del huevo, se desarrollan, hasta llegar finalmente a su
                crecimiento completo. Otras especies tienen diferentes etapas de
                larvas. El ataque por parásitos produce un esfuerzo enorme para
                el organismo, sin embargo no se reconoce en análisis clínicos
                como mecanismos biológicos extraños en progreso.&nbsp;</span
              >
            </div>
            <div style="text-align: justify">
              <font color="#000000"><br /></font>
            </div>
            <div style="text-align: justify">
              <span style="color: rgb(0, 0, 0); font-size: 1.2rem"
                >En primer lugar, todas estas etapas del desarrollo de
                parásitos, no se busca en biología o pruebas clínicas dentro del
                cuerpo humano, dado que de acuerdo a la biología ortodoxa, estos
                procesos toman lugar fuera del mismo, en huéspedes secundarios
                como ser mascotas, insectos, caracoles etc.&nbsp;</span
              >
            </div>
            <div style="text-align: justify">
              <font color="#000000"><br /></font>
            </div>
            <div style="text-align: justify">
              <span style="color: rgb(0, 0, 0); font-size: 1.2rem"
                >En segundo término, debido a los límites de las posibilidades
                de los laboratorios, los estudios clínicos solamente apuntan a
                las infecciones masivas. Esto significa que los parásitos son
                detectados sólo cuando ellos aparecen en forma visible en la
                materia fecal, acompañada por vómitos, fiebre, anemia,
                etc.&nbsp;</span
              >
            </div>
            <div style="text-align: justify">
              <span style="color: rgb(0, 0, 0); font-size: 1.2rem"
                >Sin embargo, he observado (descubrimiento de la Dra. Clark),
                que las metabolizaciones que producen los parásitos contienen
                sustancias alérgicas, debido a la cantidad de ácidos y proteínas
                extrañas al cuerpo</span
              >
            </div>
            <div style="text-align: justify">
              <font color="#000000"><br /></font>
            </div>
            <strong>
              <div style="text-align: justify">
                <b style="color: rgb(0, 0, 0); font-size: 1.2rem"
                  >&gt; Bájate el libro de la Dra. Hulda Clarck</b
                ><span
                  style="
                    color: rgb(0, 0, 0);
                    font-size: 1.2rem;
                    font-weight: 400;
                  "
                  >&nbsp;"La Cura de Todos las Enfermedades" &nbsp;</span
                ><b style="color: rgb(0, 0, 0); font-size: 1.2rem"
                  ><a
                    href="https://elobservatoriodeltiempo.files.wordpress.com/2012/09/cura-y-prevencion-de-todos-los-canceres.pdf"
                    target="_blank"
                    >&gt; Click aquí</a
                  ></b
                >
              </div>
            </strong>
            <p></p>
          </div>
        </div>
      </div>
    </section>
    <section class="header4 cid-rV24mi3V2h" id="pasos-recomendados-dioxi7">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-10 align-left">
            <h1
              class="mbr-section-title align-left mbr-white pb-2 mbr-fonts-style display-2"
            >
              <strong>Pasos recomendados</strong> antes de tomar el
              <strong>Dioxi7</strong>
            </h1>
            <p>
              Son pasos sencillos y fáciles de hacer, consulte
              <a
                href="https://api.whatsapp.com/send?phone=56961087106&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Dioxi7."
                class="float"
                target="_blank"
              >
                <i class="fa fa-whatsapp"></i>
              </a>
            </p>
            <p>
              <strong
                ><a href="recomendaciones.html"
                  >&gt; Ver Recomendaciones</a
                ></strong
              ><br /><strong
                ><a href="recomendaciones.html"><br /></a
              ></strong>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="header4 cid-rSurdNoHsY" id="content05-s6">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-10 align-left">
            <h1
              class="mbr-section-title align-left mbr-white pb-2 mbr-fonts-style display-2"
            >
              El <strong>Dioxi7</strong>&nbsp;es el eliminador Número Uno de
              <strong>Patógenos!</strong>
            </h1>
            <p class="mbr-text align-left mbr-fonts-style display-7">
              El Dioxi7(Dióxido de cloro) es uno de los asesinos más eficaces de
              agentes patógenos tales como bacterias, mohos, hongos, virus,
              bio-película y otros microrganismos causantes de enfermedades, un
              alivio total para el sistema inmunológico por que ayuda a atacar
              patógenos que normalmente no puede vencer, esto lo consigue a
              través de la interrupción de la síntesis proteínica rompiendo la
              pared celular del patógeno como si fuera la explosión de una
              granada, el Dioxi7 o MMS también crea apoptósis o muerte celular
              en celulas cancerígenas, además de activar las mitocondrias en
              celulas sanas, todo un prodigio para la buena salud.<br /><br /><a
                href="listaprotocolos.html"
                ><strong>&gt; Cada enfermedad tiene un protocolo.</strong></a
              >
              <a
                href="https://api.whatsapp.com/send?phone=52961087106&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202."
                class="float"
                target="_blank"
              >
                <i class="fa fa-whatsapp my-float"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="extContacts cid-rXAsTbKgdR" id="testimonios">
      <div class="container">
        <div class="top-content">
          <div class="container">
            <div class="row justify-content-center align-items-center">
              <div class="col-xs-12 col-sm-7 img-block align-center">
                <div class="mbr-figure">
                  <video width="100%" controls>
                    <source
                      src="@/assets/video/testimonio-chile.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div class="col-xs-12 text-block col-sm-5">
                <h2
                  class="mbr-section-title mbr-fonts-style display-2 align-left"
                >
                  Hay muchos <strong>testimonios </strong>de curaciónes con el
                  <strong>Dióxido de Cloro</strong> (Dioxi7)
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cid-rSurRlqQ2K" id="dioxi7mascotas">
      <br />
      <br />
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 content py-4 col-lg-5">
            <h1
              class="mbr-section-title align-left mbr-bold pb-4 mbr-fonts-style display-2"
            >
              <a href="http://cdsperu.net/animales.html"
                ><strong>El Dioxi7 / MMS</strong></a
              ><span style="font-weight: normal"
                ><a href="http://Dioxi7peru.net/animales.html">
                  y nuestras mascotas</a
                ></span
              ><br />
            </h1>
            <div class="align-wrap">
              <div class="icons-wrap">
                <div class="icon-wrap">
                  <span
                    class="mbr-iconfont mobi-mbri-bulleted-list mobi-mbri"
                  ></span>
                  <div class="text-wrap">
                    <h3 class="icon-title mbr-bold mbr-fonts-style display-5">
                      Dioxi7 para múltiples patologías
                    </h3>
                    <p class="mbr-text mbr-fonts-style display-7">
                      En veterinaria se usa ampliamente para infecciones virales
                      y bacterianas.
                    </p>
                  </div>
                </div>
              </div>
              <div class="icons-wrap">
                <div class="icon-wrap">
                  <span class="mbr-iconfont mobi-mbri-github mobi-mbri"></span>
                  <div class="text-wrap">
                    <h3 class="icon-title mbr-bold mbr-fonts-style display-5">
                      Dioxi7 - MMS tópico y oral
                    </h3>
                    <p class="mbr-text mbr-fonts-style display-7">
                      Es muy eficáz para infecciones en heridas y para hongos en
                      la piel.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="link-wrap">
              <img class="bone" src="assets/images/bone1.png" />
              <h3
                class="mbr-section-title mbr-white align-left mbr-bold mbr-fonts-style display-7"
              >
                <a href="http://elcdscura.com/animales.html" class="text-white"
                  >VER PROTOCOLOS</a
                >
              </h3>
            </div> -->
          </div>
          <div class="col-md-12 col-lg-7 img-col">
            <div class="mbr-figure">
              <img src="@/assets/images/09.png" alt="" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 content py-4 col-lg-7">
            <h1
              class="mbr-section-title align-left mbr-bold pb-4 mbr-fonts-style display-2"
            >
              <strong>Protocolo para animales</strong>
            </h1>
          </div>
          <div class="col-md-12 col-lg-5 img-col"></div>
        </div>
        <div class="row">
          <div id="circle-shape-example">
            <img
              src="@/assets/images/animales-circulo.jpg"
              alt="Una fotografía de perritos y gatitos"
              class="curve"
            />
            <p class="mbr-text align-left mbr-fonts-style display-7">
              A los animales se les trata de acuerdo a sus peso, igual que a los
              niños. <br />
              Para ingerir: ½ cc de Dioxi7 activada cada 11 kilos <br />
              Para las enfermedades y se usa un protocolo dando excelentes
              resultados <br />
              Para tratar a su mascota tópicamente (piel) usar el Protocolos
              para la piel en spray. Sirve para eliminar hongos, ayuda a
              cicatrizar heridas, espantar pulgas y garrapatas. <br />
              Si quiere lavarle los dientes a su mascota por malos olores,
              vierta en un vaso ½ cc de Dioxi7. Agregue agua mineral. Luego
              cepille los dientes de su mascota con la solución.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="cid-rV8ipwrvf9" id="video3-wn">
      <figure class="mbr-figure align-center container">
        <div class="video-block" style="width: 81%">
          <div>
            <video width="100%" class="video" controls>
              <source src="@/assets/video/superperrito.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </figure>
    </section>
    <lugol> </lugol>

    <section id="forever" class="header4 cid-rIAn30U1eS">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-12 align-left">
            <h1
              class="mbr-section-title align-left mbr-white pb-2 mbr-fonts-style display-2"
            >
              <b style="font-size: 2.3rem">Forever</b><br />
            </h1>
            <h4><strong>Excelencia</strong></h4>
            <h5>Cremas, Nutrición, Lociones, Alóe y más</h5>
          </div>
        </div>
      </div>
    </section>
    <forever> </forever>
    <section once="footers" class="cid-rIB1tYbpPN" id="footer1-g">
      <div class="container">
        <div class="media-container-row align-center mbr-white">
          <div class="col-12">
            <p class="mbr-text mb-0 mbr-fonts-style display-4">
              <br /><strong
                >DECLARACIÓN DE DESCARGO DE RESPONSABILIDAD DE "EL Dioxi7
                FUNCIONA"&nbsp;</strong
              ><br />La información aquí brindada de ninguna manera debe ser
              interpretada como consejo, <br />instrucción y/o dirección médica
              y no sustituye una visita a su profesional de la salud. <br />Cada
              persona es responsable del uso que decide darle a la información
              aquí obtenida.<br /><br /><a href="puntos.html">.</a> &nbsp; -<a
                href="sinergia8.html"
                >-</a
              >- &gt;&gt; &nbsp;El Dioxi7 Funciona &nbsp; &lt;&lt; -<a
                href="sinergia8.html"
                >-</a
              >- &nbsp;&nbsp;<a href="losproductos.html">.</a><br /><br /><br />
            </p>
          </div>
        </div>
      </div>
    </section>
    <input name="animation" type="hidden" />
  </div>
</template>

<script>
import Understanding from "@/components/Understanding.vue";
import Lugol from "@/components/Lugol.vue";
import Forever from "@/components/Forever.vue";
export default {
  name: "Posts",
  components: {
    Understanding,
    Lugol,
    Forever,
  },
};
</script>

<style scoped>
.float {
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
}

.my-float {
  margin-top: 16px;
}

#circle-shape-example {
  font-family: Open Sans, sans-serif;
  margin: 2rem;
}
#circle-shape-example p {
  line-height: 1.8;
}
#circle-shape-example .curve {
  width: 33%;
  height: auto;
  min-width: 120px;
  float: left;
  margin-right: 2rem;
  border-radius: 50%;
  -webkit-shape-outside: circle();
  shape-outside: circle();
}

.video {
  width: 80%;
  height: auto;
}
</style>