<template>
  <section id="lugol" class="header4 cid-rIAn30U1eS">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-12 col-lg-10 align-left">
          <h1
            class="mbr-section-title align-left mbr-white pb-2 mbr-fonts-style display-2"
          >
            <b style="font-size: 2.3rem">Mejora tu ánimo!</b><br />
            <strong>Lugol</strong>(Yodo Orgánico)
          </h1>
          <br />
          <div class="card col-12 col-md-12 col-lg-12">
            <div class="card-img align-center">
              <img src="@/assets/images/lugol.jpg" class="imagen" />
            </div>
          </div>
          <br />
          <div style="text-align: justify">
            <span style="color: rgb(35, 35, 35); font-size: 1.2rem"
              >El yodo tiene el potencial de transformar tu salud. Es un
              micronutriente esencial. Esto quiere decir que cada célula de cada
              persona lo necesita. Biólogos evolucionarios reconocen que el
              consumo de productos marinos y la absorción de yodo tuvo un papel
              importante en el desarrollo del cerebro humano y la evolución. El
              yodo también posee excelentes propiedades antibacterianas,
              anticancerígenas, antiparasitarias, antifúngicas y antivirales.<br /><br />
              Desafortunadamente, la deficiencia de yodo en nuestro mundo
              moderno es de proporciones pandémicas en la población general
              debido al desplazamiento del yodo en nuestros cuerpos por toxinas
              que existen en el medio ambiente como el bromuro, los pesticidas y
              los aditivos en la comida. Las técnicas de la agricultura moderna
              también han llevado a las deficiencias en yodo y de otros
              minerales en la tierra. Por lo tanto, las cosechas que crecen
              dentro de tierras deficientes en yodo carecen de yodo.<br /><br />
              Cierto tipo de dietas y formas de vida pueden predisponer a que
              una persona desarrolle deficiencia en yodo. Aquellos que ingieren
              muchos alimentos con harina (panes, pastas, etc.) que contienen
              altos porcentajes de bromuro, están en riesgo. También los
              vegetarianos y aquellos que no gustan del pescado y vegetales
              provenientes del mar o la sal.<br /><br />
              De acuerdo con el Dr. Brownstein, autor de Iodine:
              <em> You Need It, Why You Can't Live Without It</em>, casi un
              tercio de la población global vive en una región con déficit de
              yodo. Él junto con otros investigadores de yodo han hecho pruebas
              en cientos de personas, encontrando resultados consistentes:
              aproximadamente el 96% de los pacientes tienen niveles bajos de
              yodo. <br /><br />
              La Organización Mundial de la Salud ha reconocido que la
              deficiencia en yodo es la mayor causa de retraso mental que se
              puede prevenir. La deficiencia en yodo ha sido identificada como
              un problema de salud pública en 129 países y casi el 72% de la
              población global se ve afectada por un trastorno en deficiencia de
              yodo. <br /><br />
              El yodo fue agregado a la sal en EE.UU para prevenir el bocio.
              Pero como sabemos, las cantidades que fueron agregadas no ayudaron
              mucho para prevenir o compensar muchas otras enfermedades.
            </span>
          </div>
        </div>
      </div>
      <br /><br />
      <div class="row justify-content-md-center">
        <div class="col-md-12 col-lg-10 align-left">
          <h1
            class="mbr-section-title align-left mbr-white pb-2 mbr-fonts-style display-2"
          >
            El <strong>Lugol</strong>(Yodo Orgánico) puede mejorar
            potencialmente tu salud
          </h1>
          <h3>
            Las siguientes enfermedades están relacionadas con un déficit en
            yodo:
          </h3>
          <br /><br />
          <div style="text-align: justify">
            <span style="color: rgb(35, 35, 35); font-size: 1.2rem">
              <ul>
                <li>Cáncer de mama</li>
                <li>Cáncer de tiroides</li>
                <li>Cáncer en ovarios</li>
                <li>Cáncer en útero</li>
                <li>Cáncer de próstata</li>
                <li>Enfermedades tiroideas</li>
                <li>Hipotiroidismo</li>
                <li>Enfermedad fibroquística de las mamas</li>
                <li>TDAH (déficit de atención con hiperactividad)</li>
                <li>Síndrome de fatiga crónica</li>
                <li>Fibromialgia</li>
              </ul>
            </span>
          </div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-12 col-lg-10 align-left">
          <h1
            class="mbr-section-title align-left mbr-white pb-2 mbr-fonts-style display-2"
          >
            Lo que es más, El <strong>Lugol</strong>(Yodo Orgánico)
          </h1>
          <h3>Puede tratar las siguientes condiciones:</h3>
          <br />
          <table style="color: rgb(35, 35, 35); font-size: 1rem">
            <thead>
              <tr>
                <td>TDAH aterosclerosis</td>
                <td>miomas uterinos</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>enfermedades mamarias</td>
                <td>intoxicación por metales pesados</td>
              </tr>
              <tr>
                <td>contractura de Dupuytren</td>
                <td>neumonía</td>
              </tr>
              <tr>
                <td>producción excesiva de mucosa</td>
                <td>escarlatina</td>
              </tr>
              <tr>
                <td>enfermedad fibroquística en mamas</td>
                <td>bronquitis</td>
              </tr>
              <tr>
                <td>bocio</td>
                <td>obesidad</td>
              </tr>
              <tr>
                <td>hemorroides</td>
                <td>depresión</td>
              </tr>
              <tr>
                <td>cefaleas y migrañas</td>
                <td>mastalgias</td>
              </tr>
              <tr>
                <td>fibromialgia</td>
                <td>eccema</td>
              </tr>
              <tr>
                <td>síndrome de fatiga crónica</td>
                <td>malaria</td>
              </tr>
              <tr>
                <td>hipertensión</td>
                <td>enfermedades genitourinarias</td>
              </tr>
              <tr>
                <td>infecciones</td>
                <td>reumatismo</td>
              </tr>
              <tr>
                <td>queloides</td>
                <td>amigdalitis</td>
              </tr>
              <tr>
                <td>enfermedades del hígado</td>
                <td>tos</td>
              </tr>
              <tr>
                <td>síndrome nefrótico</td>
                <td>dolores de estómago</td>
              </tr>
              <tr>
                <td>enfermedades ováricas</td>
                <td>disfunción cognitiva</td>
              </tr>
              <tr>
                <td>cálculos del conducto parotídeo</td>
                <td>alergias</td>
              </tr>
              <tr>
                <td>enfermedad de Peyronie</td>
                <td>irregularidades de la menstruación</td>
              </tr>
              <tr>
                <td>trastornos de la próstata</td>
                <td>infección de las encías</td>
              </tr>
              <tr>
                <td>quistes sebáceos</td>
                <td>psoriasis</td>
              </tr>
              <tr>
                <td>trastornos de la tiroides</td>
                <td>arritmias cardíacas</td>
              </tr>
              <tr>
                <td>infecciones vaginales</td>
                <td>colesterol alto</td>
              </tr>
              <tr>
                <td>sífilis</td>
                <td>estreñimiento</td>
              </tr>
              <tr>
                <td>reflujo gastroesofágico</td>
                <td>adelgazamiento del cabello</td>
              </tr>
              <tr>
                <td>esclerosis múltiple</td>
                <td>diabetes tipo 2</td>
              </tr>
              <tr>
                <td>gastroparesia</td>
                <td>problemas de los ojos</td>
              </tr>
              <tr>
                <td>sobrecrecimiento bacteriano en el intestino</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <div style="text-align: justify">
            <span style="color: rgb(35, 35, 35); font-size: 1.2rem">
              Cuando se trata de un hipotiroidismo autoinmune tomar con selenium
              una pastilla diaria 50mcg. El tratamiento tiene que partir con una
              gota diaria despues del desayuno aumentando a 2 gotas en la
              segunda semana y 3 gotas en la tercera semana, partiendo con un
              lugol al 2% de solución. Éste Lugol está hecho con
              <b><em>excelentes productos de primera calidad</em></b
              >.
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "lugol",
};
</script>

<style scoped>
.imagen {
  width: 80%;
  height: auto;
}
</style>