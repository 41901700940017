<template>
  <div class="container-audio">
    <audio controls loop autoplay id="myAudio">
      <source src="@/assets/audio/relax.mp3" type="audio/mp3" />
      Your browser dose not Support the audio Tag
    </audio>
  </div>
</template>
<script>

export default {
  name: "player",
    data() {
        return{
        }
    }
};
</script>
<style scoped>
</style>