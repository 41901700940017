<template>
  <div
    id="carouselDioxi7Controls"
    class="carousel vert slide"
    data-ride="carousel"
    data-interval="30000"
  >
    <ol class="carousel-indicators">
      <li
        data-target="#carouselExampleIndicators"
        data-slide-to="0"
        class="active"
      ></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="10"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="11"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="12"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-01.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-02.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-03.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-04.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-05.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-06.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-07.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-08.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-09.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-10.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-11.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-12.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-13.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-14.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-15.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-16.jpg"
          alt="First slide"
        />
      </div>
      <div class="carousel-item ">
        <img
          class="d-block mx-auto img-fluid"
          src="@/assets/images/dioxi7/dioxi7-17.jpg"
          alt="First slide"
        />
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselDioxi7Controls"
      role="button"
      data-slide="prev"
    >
      <span
        class="carousel-control-prev-icon bg-dark rounded-circle"
        aria-hidden="true"
      ></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselDioxi7Controls"
      role="button"
      data-slide="next"
    >
      <span
        class="carousel-control-next-icon bg-dark rounded-circle"
        aria-hidden="true"
      ></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "forever",
  mounted() {
    console.log("estoy acá");
    $(".cid-rIAn30U1eS img").css("height", "");
    $(".cid-rIAn30U1eS img").css("margin", "");
    $(".cid-rIAn30U1eS img").css("padding-bottom", "");
  },
};
</script>

<style scoped>
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
</style>