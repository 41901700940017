<template>
  <div id="app">
    <Player></Player>
    <Nav></Nav>
    <Banner></Banner>
    <Posts></Posts>
  </div>
</template>

<script>
import $ from "jquery";

function bigBro() {
  let x = document.getElementById("myAudio");
  x.play();
}

$(document).ready(function () {
  $(document).bind("click keydown keyup", bigBro);
  setTimeout(function () {
    let a = $('a:contains("GetButton")');
    a.remove();
  }, 2500);
});
$(".a[href='#lugol'] .a[href='#dioxi7'] .a[href='#dioxi7mascotas']").on(
  "click",
  function (event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();
      // Store hash
      var hash = this.hash;
      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (900) specifies the number of milliseconds it takes to scroll to the specified area
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top,
        },
        900,
        function () {
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        }
      );
    } // End if
  }
);

import Player from "@/components/Player.vue";
import Nav from "@/components/Nav.vue";
import Banner from "@/components/Banner.vue";
import Posts from "@/components/Posts.vue";

export default {
  name: "App",
  components: {
    Nav,
    Banner,
    Posts,
    Player,
  },
};
</script>

<style>
/* 
    @import "./assets/web/assets/mobirise-icons-bold/mobirise-icons-bold.css";
    @import "./assets/icon54/style.css"; */

@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-material-design/dist/css/bootstrap-material-design.min.css";

/* @import "./assets/bootstrap-material-design-font/css/material.css"; */
@import "./assets/web/assets/mobirise-icons/mobirise-icons.css";
@import "./assets/web/assets/mobirise-icons2/mobirise2.css";
@import "./assets/tether/tether.min.css";
/* @import "./assets/bootstrap/css/bootstrap.min.css";
@import "./assets/bootstrap/css/bootstrap-grid.min.css";
@import "./assets/bootstrap/css/bootstrap-reboot.min.css"; */
@import "./assets/dropdown/css/style.css";
@import "./assets/as-pie-progress/css/progress.min.css";
@import "./assets/animatecss/animate.min.css";
@import "./assets/socicon/css/styles.css";
@import "./assets/theme/css/style.css";
@import "./assets/mobirise/css/mbr-additional.css";

</style>
