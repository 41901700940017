import Vue from "vue";
import App from "./App.vue";
import "bootstrap";
import "jarallax";
import $ from "jquery";
import "@mdi/font/css/materialdesignicons.css";
Vue.prototype.jQuery = $;
window.jQuery = $;
Vue.config.productionTip = false;
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

new Vue({
  render: (h) => h(App),
  beforeMount() {
    let base = process.env.BASE_URL;
    let script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/web/assets/jquery/jquery.min.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/popper/popper.min.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/tether/tether.min.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/dropdown/js/nav-dropdown.js";
    document.head.appendChild(script);
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/dropdown/js/navbar-dropdown.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/mbr-popup-btns/mbr-popup-btns.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/mbr-popup-btns/mbr-popup-btns.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/as-pie-progress/jquery-as-pie-progress.min.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/mbr-switch-arrow/mbr-switch-arrow.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/smoothscroll/smooth-scroll.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/viewportchecker/jquery.viewportchecker.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/touchswipe/jquery.touch-swipe.min.js";
    document.head.appendChild(script);
    script = document.createElement("script");
    script.async = true;
    script.src = base + "assets/theme/js/script.js";
    document.head.appendChild(script);
  }
}).$mount("#app");
