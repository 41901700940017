<template>
  <section class="cid-rIzVYAoYt0 mbr-parallax-background" id="header3-1">
      <div
        class="mbr-overlay"
        style="opacity: 0; background-color: rgb(24, 24, 24)"
      ></div>
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        preserveAspectRatio="true"
        viewBox="0 0 858 235"
        style=""
      >
        <title>my vector image</title>
        <rect
          id="backgroundrect"
          width="100%"
          height="100%"
          x="0"
          y="0"
          fill="transparent"
          stroke="none"
          class=""
          style=""
        ></rect>
        <g class="currentLayer" style="">
          <title>Layer 1</title>
          <path
            fill="#4a90d6"
            fill-opacity="1"
            stroke="none"
            stroke-width="2"
            stroke-dasharray="none"
            stroke-linejoin="round"
            stroke-linecap="butt"
            stroke-dashoffset=""
            fill-rule="nonzero"
            opacity="1"
            marker-start=""
            marker-mid=""
            marker-end=""
            d="M13.91718,157.55035C128.42169,209.00593 174.16977,66.43387 406.36266,148.04844C638.55554,229.66302 649.93992,110.39426 837.69295,145.53147C1025.44597,180.66868 893.71138,356.73312 396.9054,368.57034C-99.90058,380.40757 -100.58733,106.09477 13.91718,157.55035z"
            id="svg_1"
            class=""
          ></path>
        </g>
      </svg> -->
      <div class="container">
        <div class="row">
          <div class="col-md-7 content align-left col-lg-6">
            <h1
              class="mbr-section-title align-left mbr-white mbr-bold pb-3 mbr-fonts-style display-1"
            >
              Somos <br />Dioxi7 ¡Funciona!
            </h1>
            <p
              class="mbr-text pb-3 mbr-white mbr-semibold align-left mbr-fonts-style display-5"
            >
              Todos tenemos el derecho a curarnos tanto nosotros y nuestras
              mascotas.
            </p>
            <div class="align-left mbr-section-btn">
              <a class="btn btn-md btn-primary display-4" href="#dioxi7"
                >QUÉ ES Dioxi7?</a
              >
              <a class="btn btn-md btn-warning display-4" href="#lugol"
                >QUÉ ES LUGOL?</a
              >
              <a class="btn btn-md btn-danger display-4" href="#forever"
                >QUÉ ES FOREVER?</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
name: "App",
}
</script>

<style>

</style>