<template>
  <section class="menu cid-rIzVXDkYYf" once="menu" id="menu1-0">
    <nav class="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
      <div class="container">
        <div class="navbar-brand">
          <span class="navbar-logo">
            <a href="index.html">
              <img
                src="@/assets/images/sanacionalternativa.png"
                alt=""
                title=""
                style="height: 10rem"
              />
            </a>
          </span>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <div class="hamburger">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
            <li class="nav-item">
              <a
                class="nav-link link dropdown-toggle text-primary display-4"
                href="#dioxi7"
                aria-expanded="false"
              >
                Qué es el Dioxi7?</a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link link dropdown-toggle text-primary display-4"
                href="#"
                data-toggle="dropdown-submenu"
                aria-expanded="false"
              >
                Características</a
              >
              <div class="dropdown-menu">
                <a
                  class="dropdown-item text-primary display-4"
                  href="#puntos-tener-cuenta"
                  >Puntos a tener en cuenta</a
                ><a
                  class="dropdown-item text-primary display-4"
                  href="#usos-multifaceticos-dioxi7"
                  >Los Usos del Multifacético Dioxi7</a
                ><a
                  class="dropdown-item text-primary display-4"
                  href="#importancia-probioticos"
                  >Importancia de probióticos</a
                ><a
                  class="dropdown-item text-primary display-4"
                  href="#dr-hulda-clark"
                  >Dra. Hulda Clark</a
                >
                <a
                  class="dropdown-item text-primary display-4"
                  href="#pasos-recomendados-dioxi7"
                  >Pasos recomendados antes de tomar el Dioxi7</a
                >
                <a
                  class="dropdown-item text-primary display-4"
                  href="#testimonios"
                  >Testimonios Variados</a
                ><a
                  class="dropdown-item text-primary display-4"
                  href="#dioxi7mascotas"
                  >Mascotas</a
                ><a class="dropdown-item text-primary display-4" href="#lugol"
                  >Lugol</a
                >
              </div>
            </li>
            <div class="navbar-buttons mbr-section-btn">
              <a class="btn btn-sm btn-primary-outline display-4" href="tel:+56961087106">+56961087106</a>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>
<script>
export default {
  name: "Nav",
};
</script>
<style scoped>
</style>
