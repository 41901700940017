<template>
  <section class="header4 cid-rIAn30U1eS" id="dioxi7">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-12 col-lg-10 align-left">
          <h1
            class="mbr-section-title align-left mbr-white pb-2 mbr-fonts-style display-2"
          >
            Entendiendo qué es el <strong>MMS </strong>y<strong> Dioxi7</strong>
          </h1>
          <br />
          <div class="card col-12 col-md-12 col-lg-12">
            <div class="card-img align-center">
              <img src="@/assets/images/dioxi7.jpg" class="imagen" />
            </div>
          </div>
          <br />
          <div style="text-align: justify">
            <span style="color: rgb(35, 35, 35); font-size: 1.2rem"
              >El Dioxi7 es una solución mineral con una química muy simple.
              Dioxi7 es el término utilizado para describir a una mezcla de
              Clorito de Sodio (que es un mineral extraído del agua de mar) y un
              Ácido Activador; luego de juntar estas dos sustancias se hace una
              reacción que crea y libera un gas llamado Dióxido de Cloro
              (Dioxi7) y debido a esta estructura del ión actúa como un fuerte
              oxigenante eléctrico, arrancando todos los electrones a los
              patógenos que están por debajo de un pH de 7.&nbsp;</span
            >
          </div>
          <div style="text-align: justify">
            <font color="#232323"><br /></font>
          </div>
          <div style="text-align: justify">
            <span style="color: rgb(35, 35, 35); font-size: 1.2rem"
              >Los patógenos malignos, que suelen ser anaeróbicos, tienen un pH
              inferior de 7, esto incluye virus, bacterias, hongos, parásitos,
              microbios, venenos, metales pesados, bio-película, células
              cancerígenas, y nuestras celulas sanas tienen un pH por encima de
              7, por lo que el Dioxi7 no daña sus membranas, como ocurre con
              otras terapias oxigenantes (ozono, peróxido de hidrógeno), por el
              contrario el Dioxi7 recarga con energía las mitocondrias de
              nuestras células y aumenta la conectividad entre las mismas, sin
              que su potencial de oxidación llegue a perjudicar a nuestras
              células humanas.&nbsp;</span
            >
          </div>
          <div style="text-align: justify">
            <font color="#232323"><br /></font>
          </div>
          <div style="text-align: justify">
            <font color="#232323"><br /></font>
          </div>
          <div style="text-align: justify">
            <span style="color: rgb(35, 35, 35); font-size: 1.2rem"
              >El Dióxido de Cloro actúa como municiones de alto calibre para el
              sistema inmunológico ayudándole a atacar patógenos que normalmente
              no podría vencer, esto lo consigue a través de la interrupción de
              la síntesis proteínica, rompiendo la pared celular del patógeno
              como si fuera la explosión de una granada. El Dioxi7 también crea
              apoptósis o muerte celular en celulas cancerígenas, además de
              activar las mitocondrias en celulas sanas, todo un prodigio para
              la buena salud, por que todos tenemos el derecho a vivir y morir
              sanos.
            </span>
          </div>
          <p></p>

          <div style="text-align: justify">
            <span style="color: rgb(35, 35, 35); font-size: 1.2rem">
              <p>
                Dioxi7 mata patógenos, virus, bacterias, hongos y ciertos tipos
                de parásitos por oxidación.
              </p>
            </span>
          </div>
          <dioxi7></dioxi7>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Dioxi7 from "@/components/Dioxi7.vue";
export default {
  components: { Dioxi7 },
  name: "understanding",
};
</script>

<style scoped>
.imagen {
  width: 80%;
  height: auto;
}
</style>